import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from '~/libs/utils';

export const Monospaced = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>(({ className, ...props }, ref) => (
  <code
    ref={ref}
    className={cn('border-accent/20 rounded border bg-accent p-1 font-mono text-accent-foreground', className)}
    {...props}
  />
));
Monospaced.displayName = 'Monospaced';
